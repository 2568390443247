import React from 'react'

import * as styles from './newsletter-signup.module.css'

const NewsletterSignup = () => (
    <div className={styles.container}>
        <h2>Sign up for my newsletter</h2>
        <iframe className={styles.newsletterBox} title="Newsletter Sign-Up" src="https://andresmedrano.substack.com/embed"></iframe>
    </div>
)

export default NewsletterSignup
